<template lang="pug">
  div(v-if="!isLoading").wrap-module-team-details
    div(v-if="team").module-team-container.py12
      h4.mb12 チーム名
      div {{ team.name }}

      h4.mt12 メンバーシップドメイン
      div(v-for="item in team.membershipDomains", :key="item") @{{ item }}
      div(v-if="!team.membershipDomains[0]").f.flex-right.mt16
        a(@click="$router.push('/team/update')").fz14 追加

      v-list
        h4.mt12 メンバー
        v-list-item(v-if="members" v-for="item in members", :key="item.id")
          v-list-item-avatar
            v-img(:src="item.iconURL")
          v-list-item-content
            v-list-item-title {{ item.name }}
            v-list-item-subtitle {{ item.email }}
          v-list-item-action
            div {{ item.id === team.owner ? 'Owner' : '' }}

        h4.mt12(v-if="invitedEmails && invitedEmails.length > 0") 招待されたEメール
        v-list-item(v-if="invitedEmails && invitedEmails.length > 0" v-for="item in invitedEmails", :key="item")
          v-list-item-avatar
            v-icon mdi-email
          v-list-item-content
            v-list-item-title {{ item }}
    div.container(v-else)
      div チームのデータを見つけられませんでした
</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.wrap-module-team-details {
  .module-team-container {
    width: $base_width_percent;
    max-width: $base_max_width_px;
    margin: 0 auto;
  }
}
</style>

<style lang="scss">
.wrap-module-team-details {
  .v-list-item {
    padding: 0;
  }
}
</style>

<script>
import format from 'date-fns/format'
import database from '@/database'
import { createNamespacedHelpers } from 'vuex'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')
const teamStore = createNamespacedHelpers('team')

export default {
  components: {
  },
  computed: {
    ...mapStateAuth(['uid']),
    ...teamStore.mapState(['team'])
  },
  data () {
    return {
      isLoading: true,
      invitedEmails: [],
      members: []
    }
  },
  async created () {
    this.isLoading = false
    if (this.team) {
      const owner = await database.userCollection().findById(this.team.owner)
      this.members = [
        owner
      ]
      this.invitedEmails = this.team.invitedEmails

      const members = await database.userCollection().findListByIds(this.team.members.filter((v) => v!== this.team.owner))
      this.members = [
        owner,
        ...members
      ]
    }
  },
  methods: {
    
  }
}
</script>
