<template lang="pug">
Auth(@loginFailed="onFailedAuthentication")
  Header(:content="headerContent")
  DrawerMenu(v-if="uid"
    :uid="uid" :show="showMenu" @close="toggleMenu")
  div.wrap-team-details
    ModuleTeamDetails(v-if="uid")
</template>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.wrap-team-details {
  margin-top: $header_height;
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
import Auth from '@/components/shared/Auth'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
const teamStore = createNamespacedHelpers('team')
import Header from '@/components/shared/Header'
import ModalPage from '@/components/shared/ModalPage.vue'
import DrawerMenu from '@/components/shared/DrawerMenu'
import ModuleTeamDetails from '@/components/module/ModuleTeamDetails'

export default {
  components: {
    Auth,
    Header,
    DrawerMenu,
    ModalPage,
    ModuleTeamDetails
  },
  computed: {
    ...mapStateAuth(['uid']),
    ...teamStore.mapState(['team'])
  },
  data() {
    return {
      headerContent: {
        title: 'チーム',
        left: {
          icon: 'mdi-menu',
          action: this.toggleMenu
        }
      },
      showMenu: false
    }
  },
  watch: {
    team() {
      this.updateUpdateButton()
    }
  },
  created() {
    this.updateUpdateButton()
  },
  methods: {
    onFailedAuthentication() {
      this.$router.push('/sign-in')
    },
    back() {
      this.$router.back()
    },
    toggleMenu() {
      this.showMenu = !this.showMenu
    },
    update() {
      this.$router.push('/team/update')
    },
    updateUpdateButton() {
      if (this.team && this.team.owner === this.uid) {
        this.headerContent = {
          ...this.headerContent,
          right: {
            label: '編集',
            action: this.update,
            color: '#4285f4'
          }
        }
      } else {
        this.headerContent = {
          ...this.headerContent,
          right: null
        }
      }
    }
  }
}
</script>
